import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 300.000000 300.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)">

        
<path d="M1378 2021 c-105 -35 -179 -104 -230 -216 -18 -40 -22 -68 -22 -136
0 -77 3 -93 32 -151 142 -289 539 -285 674 8 19 40 23 66 23 144 0 88 -3 101
-33 162 -60 123 -173 197 -312 204 -55 3 -90 -1 -132 -15z m256 -31 c72 -27
148 -101 183 -176 37 -81 41 -163 13 -255 -19 -63 -30 -81 -83 -134 -84 -84
-148 -109 -267 -103 -66 4 -96 10 -135 30 -68 35 -128 97 -162 166 -24 50 -28
69 -28 147 0 77 4 98 27 147 81 173 268 246 452 178z"/>
<path d="M1433 1888 c-24 -6 -43 -15 -43 -19 0 -5 -6 -9 -14 -9 -24 0 -84 -80
-99 -131 -17 -57 -5 -146 25 -184 65 -82 112 -107 201 -108 52 -1 72 4 121 31
32 18 55 32 51 32 -5 0 1 10 13 23 12 12 28 41 36 63 14 42 19 154 6 154 -4 0
-11 12 -15 28 -8 31 -60 83 -108 105 -44 21 -123 28 -174 15z m123 -44 c-5
-14 -4 -15 8 -5 10 8 16 9 21 1 3 -6 10 -8 14 -6 4 3 27 -14 50 -38 46 -47 67
-106 55 -160 -10 -49 -46 -106 -66 -106 -16 0 -17 3 -8 20 6 11 9 24 6 28 -3
4 -7 55 -10 112 -4 93 -7 106 -28 121 -20 15 -25 16 -50 3 -15 -8 -28 -22 -28
-32 -1 -12 -9 -8 -30 18 -43 50 -39 60 21 60 43 0 50 -3 45 -16z m-84 -96 l36
-82 17 42 c14 33 22 42 41 42 24 0 24 -1 24 -109 0 -60 3 -116 6 -125 3 -9 2
-16 -3 -16 -19 0 -33 52 -34 123 l-1 72 -25 -55 -25 -55 -23 50 -23 50 -1 -47
c-1 -41 -4 -48 -21 -48 -19 0 -20 7 -20 120 0 66 3 120 8 120 4 0 24 -37 44
-82z m-77 62 c-3 -5 -7 -54 -10 -109 -3 -94 -2 -100 20 -121 21 -20 28 -21 57
-11 28 10 35 9 45 -6 7 -9 13 -22 13 -28 0 -6 11 -18 25 -25 14 -7 25 -16 25
-20 0 -4 -25 -10 -57 -13 -43 -5 -64 -2 -93 13 -50 25 -108 94 -116 138 -10
51 12 121 49 160 29 30 56 44 42 22z m195 -15 c15 -18 5 -35 -20 -35 -10 0
-22 5 -25 10 -7 11 12 40 25 40 4 0 13 -7 20 -15z"/>
<path d="M875 1525 c0 -264 1 -371 2 -237 2 134 2 350 0 480 -1 130 -2 21 -2
-243z"/>
<path d="M1070 1210 c0 -22 0 -43 -1 -47 0 -4 4 -9 10 -11 6 -2 11 9 12 25 1
27 1 27 8 4 10 -36 31 -38 32 -4 1 27 1 27 8 4 4 -14 13 -27 20 -29 10 -3 12
0 8 12 -4 10 -7 33 -7 52 0 44 -25 46 -35 2 l-7 -33 -11 33 c-14 46 -37 42
-37 -8z"/>
<path d="M1190 1200 c0 -27 5 -50 10 -50 6 0 10 23 10 50 0 28 -4 50 -10 50
-5 0 -10 -22 -10 -50z"/>
<path d="M1237 1244 c-14 -14 -7 -43 13 -49 11 -3 20 -11 20 -16 0 -6 -9 -7
-21 -4 -15 5 -20 3 -17 -6 7 -21 43 -22 59 -3 12 14 10 19 -16 41 -26 21 -27
24 -9 21 11 -2 22 2 23 7 2 12 -41 19 -52 9z"/>
<path d="M1318 1225 c-22 -49 25 -98 60 -63 7 7 12 24 12 38 0 51 -52 69 -72
25z m47 -25 c0 -27 -20 -38 -28 -15 -8 20 3 47 17 42 6 -2 11 -14 11 -27z"/>
<path d="M1410 1200 l0 -50 35 0 c19 0 35 5 35 10 0 6 -9 10 -20 10 -17 0 -20
7 -20 40 0 29 -4 40 -15 40 -11 0 -15 -12 -15 -50z"/>
<path d="M1510 1225 c-19 -49 -22 -75 -11 -75 6 0 11 5 11 10 0 14 38 12 43
-2 2 -7 8 -10 12 -6 10 9 -18 91 -33 96 -7 2 -16 -8 -22 -23z"/>
<path d="M1590 1200 c0 -39 4 -50 16 -50 31 0 54 20 54 48 0 34 -16 52 -47 52
-21 0 -23 -4 -23 -50z m45 0 c0 -13 -6 -25 -12 -27 -9 -3 -13 6 -13 27 0 21 4
30 13 28 6 -3 12 -15 12 -28z"/>
<path d="M1691 1213 c-7 -21 -15 -44 -17 -50 -3 -7 1 -13 9 -13 9 0 17 6 20
13 3 9 9 9 27 -1 12 -7 24 -11 27 -8 9 9 -29 96 -42 96 -6 0 -17 -17 -24 -37z"/>
<path d="M1770 1240 c0 -5 5 -10 10 -10 6 0 10 -18 10 -40 0 -29 4 -40 15 -40
11 0 15 11 15 40 0 22 5 40 10 40 6 0 10 5 10 10 0 6 -16 10 -35 10 -19 0 -35
-4 -35 -10z"/>
<path d="M1865 1204 c-20 -52 -16 -61 15 -42 18 10 24 10 27 1 3 -7 11 -13 18
-13 11 0 10 10 -3 50 -9 28 -21 50 -28 50 -6 0 -19 -21 -29 -46z"/>
<path d="M1247 903 c133 -2 353 -2 490 0 136 1 28 2 -242 2 -269 0 -381 -1
-248 -2z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
